.table-layout-fixed {
  table-layout: fixed;
}

.table-vertical-align-text tr {
  vertical-align: text-top;
}

.word-wrap-break-word {
  word-wrap: break-word;
}

:host ::ng-deep .p-datatable .p-datatable-wrapper {
  overflow: visible;
}

:host ::ng-deep .p-datatable .p-datatable-thead > tr > th {
  position: sticky;
  position: -webkit-sticky;
  top: 4rem;
}

:host ::ng-deep .p-datatable .p-datatable-tbody > tr:nth-child(even) {
  background: #fafafa;
  transition: none;
}

:host ::ng-deep .p-datatable .p-datatable-tbody > tr:hover {
  background: #fafafa;
}

tr:nth-child(even) {
  background: rgba(0, 0, 0, 0.02);
}
