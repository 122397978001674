.p-calendar-w-btn {
  border: $inputBorder;
  background: $inputBg;
  border-radius: $borderRadius;
  transition: $formElementTransition;

  .p-inputtext {
    background-image: none;
    border: 0 none;

    &:enabled:focus {
      box-shadow: none;
    }
  }

  .p-datepicker-trigger.p-button {
    border: 0 none;

    span {
      color: $inputIconColor;
    }
    /* &:enabled:hover {
            background: rgba($overlayColor, .04);
        }

        &:enabled:active,
        &:focus {
            background: rgba($overlayColor, .12);
        }

        &:disabled {
            background-color: transparent !important;
        } */
  }

  &:not(.p-calendar-disabled):hover {
    border-color: $inputHoverBorderColor;
  }

  &:not(.p-calendar-disabled).p-focus {
    border-color: $inputFocusBorderColor;
    box-shadow: inset 0 0 0 1px $inputFocusBorderColor,inset 0 0 0 1px $inputFocusBorderColor,inset 0 0 0 1px $inputFocusBorderColor,inset 0 0 0 1px $inputFocusBorderColor;
  }

  &.p-calendar-disabled {
    opacity: $disabledOpacity;
  }
}

.p-datepicker {
    .p-datepicker-header {
        border-bottom: 0 none;

        .p-datepicker-title {
            margin: 0 auto 0 0;
            order: 1;
        }

        .p-datepicker-prev {
            order: 2;
        }

        .p-datepicker-next {
            order: 3;
        }
    }

    table {
        th {
            border-bottom: $calendarHeaderBorder;
            color: $emphasis-low();
            font-weight: 400;
            font-size: .875rem;
        }


        td {
            &.p-datepicker-today {
                > span {
                    box-shadow: 0 0 0 1px rgba($overlayColor,.38);
                }

                &.p-highlight {
                    box-shadow: 0 0 0 1px $highlightBg;
                }
            }
        }
    }
}

p-calendar.ng-dirty.ng-invalid {
    .p-inputtext {
        &:enabled:focus {
            box-shadow: inset 0 0 0 1px $inputErrorBorderColor,inset 0 0 0 1px $inputErrorBorderColor,inset 0 0 0 1px $inputErrorBorderColor,inset 0 0 0 1px $inputErrorBorderColor;
        }
    }

    .p-calendar-w-btn {
        border-color: $inputErrorBorderColor;

        .p-inputtext {
            &:enabled:focus {
                box-shadow: none;
            }
        }

        &:not(.p-disabled).p-focus {
            box-shadow: inset 0 0 0 1px $inputErrorBorderColor,inset 0 0 0 1px $inputErrorBorderColor,inset 0 0 0 1px $inputErrorBorderColor,inset 0 0 0 1px $inputErrorBorderColor;
        }
    }
}

.p-input-filled {
    .p-calendar-w-btn {
        @include filled-input-wrapper-default();

        &:not(.p-calendar-disabled) {
            @include filled-input-wrapper-interaction();
        }

        .p-inputtext {
            border: 0 none;
        }
    }

    p-calendar.ng-dirty.ng-invalid {
        .p-inputtext {
            @include invalid-filled-input();
        }

        .p-calendar-w-btn {
            @include invalid-filled-input-wrapper();

            .p-inputtext {
                border: 0 none;
                background-image: none;
            }
        }
    }
}
