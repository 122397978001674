$primaryColor:#045834;
$primaryLightestColor: #E8EAF6;
$primaryMenuTextColor: $primaryColor;
$primaryTextColor:#ffffff;
$accentColor:#dddddd;
$accentTextColor: #000000de;
$accentMenuColor: #ffa400;

@import '../_variables';
@import '../../_compact';
@import '../../designer/_components';
@import '../../extensions/_vendor_extensions';
