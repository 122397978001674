.p-carousel {
    .p-carousel-indicators {

        .p-carousel-indicator {
            &.p-highlight {
                button {
                    background: $accentColor;
                    color: $accentTextColor;
                }
            }
        }
    }
}
