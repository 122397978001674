p-inputnumber.ng-dirty.ng-invalid {
    .p-inputtext {
        &:enabled:focus {
            box-shadow: inset 0 0 0 1px $inputErrorBorderColor,inset 0 0 0 1px $inputErrorBorderColor,inset 0 0 0 1px $inputErrorBorderColor,inset 0 0 0 1px $inputErrorBorderColor;
        }
    }
}

.p-input-filled {
    p-inputnumber.ng-dirty.ng-invalid {
        .p-inputtext {
            @include invalid-filled-input();
        }
    }
}