.p-chips {
    .p-chips-multiple-container {
        &:not(.p-disabled).p-focus {
            box-shadow: inset 0 0 0 1px $inputFocusBorderColor,inset 0 0 0 1px $inputFocusBorderColor,inset 0 0 0 1px $inputFocusBorderColor,inset 0 0 0 1px $inputFocusBorderColor;
        }
    }
}

.p-input-filled {
    .p-chips-multiple-container {
        @include filled-input-wrapper();
    }
}

.p-float-label {
    .p-chips-multiple-container {
        .p-chips-token {
            padding: .25rem 1rem;
        }
    }
}

.p-input-filled {
    .p-float-label {
        .p-chips {
            .p-chips-multiple-container {
                .p-chips-token {
                    padding-top: 0;
                    padding-bottom: 0;

                    .p-chips-token-icon {
                        font-size: 75%;
                    }
                }

                .p-chips-input-token {
                    padding: 0;
                }
            }
        }
    }

    p-chips.ng-dirty.ng-invalid {
        .p-chips .p-chips-multiple-container {
            @include invalid-filled-input-wrapper();
        }
    }
}

p-chips.ng-dirty.ng-invalid {
    .p-chips .p-chips-multiple-container {
        &:not(.p-disabled).p-focus {
            box-shadow: inset 0 0 0 1px $inputErrorBorderColor,inset 0 0 0 1px $inputErrorBorderColor,inset 0 0 0 1px $inputErrorBorderColor,inset 0 0 0 1px $inputErrorBorderColor;
        }
    }
}