.background-special {
  background-color: rgba(245, 154, 35, 0.19);
}

.alternate .row:nth-child(even) {
  background-color: #f2f2f2;
}

.disabled-div {
  pointer-events: none;
  opacity: 0.5; /* This makes the div and its content look faded */
  background-color: #808080;
}

.error-message {
  color: red;
}

.wide-tooltip {
  max-width: 25em;
  white-space: normal;
  background-color: rgb(246, 246, 251) !important;
  color: black;
}
