.p-dialog .p-dialog-content {
  background-color: var(--surface-ground);
}

.filter-button-margin {
  margin-top: 1.85rem !important;
}

.p-dropdown{
    width:inherit;
}
.p-dialog .p-dialog-header {
  border-bottom: 1px solid var(--surface-300);
}

div.red-content a{
  color: red !important;
}

.p-splitbutton-menubutton {
  border-top-left-radius: 0 ;
  border-bottom-left-radius: 0;
}

.p-splitbutton-defaultbutton {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.p-datepicker-other-month {
  visibility: hidden;
}